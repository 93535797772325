import Vue from 'vue'
import VueRouter from 'vue-router'

import { subjectAbility } from '@/constant'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, isUserHaveCompany, redirectLoggedInUser } from '@/auth/utils'

import auth from './routes/auth'
import membership from './routes/membership'
import dashboard from './routes/dashboard'
import masterData from './routes/master-data'
import transactions from './routes/transactions'
import chbacc from './routes/chbacc'
import report from './routes/report'
import setting from './routes/setting'
import profile from './routes/profile'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    ...auth,
    ...membership,
    ...dashboard,
    ...masterData,
    ...transactions,
    ...chbacc,
    ...report,
    ...setting,
    ...profile,
    {
      path: '/pages/not-authorized',
      name: 'not-authorized',
      // ! Update import path
      component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
      meta: {
        shouldLogin: false,
        action: 'read',
        resource: subjectAbility.General
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        shouldLogin: false,
        action: 'read',
        resource: subjectAbility.General
      }
    },
    {
      path: '*',
      shouldLogin: false,
      action: 'read',
      resource: subjectAbility.General,
      redirect: 'error-404'
    }
  ]
})

router.beforeEach((to, _, next) => {
  // check if router have defined shouldLogin meta, if not defined, set default to true
  const routeShouldLogin = to.meta.shouldLogin === undefined ? true : to.meta.shouldLogin
  const userAlreadyLoggedIn = isUserLoggedIn()
  const isHaveCompany = isUserHaveCompany()
  
  if (routeShouldLogin && !userAlreadyLoggedIn) {
    return next({ name: 'login' })
  }
  
  if (to.name !== 'membership-company-register' && userAlreadyLoggedIn && !isHaveCompany) {
    next({ name: 'membership-company-register' })
  }

  if (to.name !== 'not-authorized' && !routeShouldLogin && userAlreadyLoggedIn) {
    return next('/')
  }
  
  if (!canNavigate(to)) {
    return next({ name: 'not-authorized' })
  }

  // // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && userAlreadyLoggedIn) {
    next(redirectLoggedInUser())
  }

  return next()
})

export default router
